.EmptyScreen {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 32px;
  height: 100%;
  padding: 32px 24px;
  background-color: @background-secondary;
  border-radius: 24px;

  @media @mobile {
    gap: 24px;
    padding: 24px 16px;
    border-radius: 16px;
  }
}

.EmptyScreenTitle {
  .Headline2();
  text-align: center;

  @media @mobile {
    .Headline3();
  }
}