.LeftMenuOptionIconOnlyWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  flex-shrink: 0;

  &:hover {
    background-color: @background-secondary;
  }

  &:hover svg {
    fill: @icon-secondary;
  }

  &.LeftMenuItemActive svg {
    fill: @icon-secondary;
  }

  &.LeftMenuItemActive::before {
    content: '';
    position: absolute;
    left: 0;
    z-index: 2;
    width: 2px;
    height: 48px;
    background-color: @icon-secondary;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.LeftMenuSingleItem.LeftMenuItemActive:hover,
.LeftMenuItem.LeftMenuItemActive:hover {
  background-color: @background-secondary;

  & svg {
    fill: @icon-secondary;
  }
}

.LeftMenuSingleItem, .LeftMenuItem {
  &:hover svg {
    fill: @icon-secondary;
  }
}

.LeftMenuItemActive {
  position: relative;
}

.LeftMenuItemActive::before {
  content: '';
  position: absolute;
  left: 0;
  z-index: 2;
  width: 2px;
  height: 48px;
  background-color: @icon-secondary;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.LeftMenuItemHeaderActive .ant-collapse-header {
  background-color: @background-secondary;
}

.LeftMenuOptionIconOnlyWrapper:hover svg {
  fill: @icon-secondary;
}

.LeftMenuOptionActive .LeftMenuOptionText {
  color: @text-tertiary;
}

.LeftMenuOptionActive {
  position: relative;
}

.LeftMenuOptionActive::before {
  content: '';
  position: absolute;
  left: 0;
  z-index: 2;
  width: 2px;
  height: 48px;
  background-color: @icon-secondary;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.LeftMenuSingleItem .LeftMenuIconWrapper {
  margin-top: 4px;
}

.LeftMenuIconWrapper svg {
  color: @icon-primary;
}

.LeftMenuSingleItem {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  padding: 0 24px;

  &:hover {
    background-color: @background-secondary;
  }
}

.LeftMenuSingleItemLink {
  display: flex;
  align-items: center;
  gap: 12px;
  height: 48px;

  cursor: pointer;
  .Subtitle2();
  text-decoration: none;
}

.LeftMenuItemActive .LeftMenuOptionText {
  color: @text-tertiary;
}

.LeftMenuItemActive svg {
  fill: @icon-secondary;
}

.LeftMenuOptionActive span {
  color: @text-tertiary;
}
