// input
.InputWrapper > .ant-input,
.InputWrapper > .ant-input-number,
.InputWrapper > .ant-input-affix-wrapper,
.InputWrapper > .ant-input-password {
  box-shadow: none !important;
  background-color: @background-tertiary;
  border: 1px solid @background-tertiary;

  &::placeholder {
    color: @text-complementary;
  }
}

.InputWrapper > .ant-input-affix-wrapper > .ant-input {
  box-shadow: none !important;
  background-color: transparent;

  &::placeholder {
    color: @text-complementary;
  }
}

.InputWrapper:has(:not(:disabled)) {
  & > .ant-input:hover,
  & > .ant-input-number:hover,
  & > .ant-input-affix-wrapper:hover,
  & > .ant-input:active,
  & > .ant-input-number:active,
  & > .ant-input-affix-wrapper:active,
  & > .ant-input:focus,
  & > .ant-input-number:focus,
  & > .ant-input-affix-wrapper:focus,
  & > .ant-input-affix-wrapper:has(> .ant-input:focus) {
    box-shadow: none !important;
    background-color: @hover-secondary !important;
  }
}

.InputWrapper:has(:not(:disabled)) {
  & > .ant-input-affix-wrapper:hover > .ant-input,
  & > .ant-input-affix-wrapper:active > .ant-input,
  & > .ant-input-affix-wrapper:focus > .ant-input,
  & > .ant-input-affix-wrapper-focused > .ant-input {
    box-shadow: none !important;
    background-color: transparent !important;
  }
}

// icons
.InputWrapper:has(.ant-input:placeholder-shown) .ant-input-suffix > svg > path {
  fill: @text-complementary;
}

.InputWrapper:has(.ant-input:not(:placeholder-shown)) .ant-input-suffix > svg > path {
  fill: @text-primary;
}

// borders
.InputWrapper:not(.ErrorInputWrapper) {
  & > .ant-input:hover,
  & > .ant-input-number:hover,
  & > .ant-input-affix-wrapper:hover,
  & > .ant-input:active,
  & > .ant-input-number:active,
  & > .ant-input-affix-wrapper:active {
    border: 1px solid @hover-secondary;
  }

  & > .ant-input:focus,
  & > .ant-input-number:focus,
  & > .ant-input-affix-wrapper:focus,
  & > .ant-input-affix-wrapper:has(> .ant-input:focus) {
    border: 1px solid @hover-primary;
  }
}

.InputWrapper.InputWrapperBorderless {
  & > .ant-input,
  & > .ant-input-number,
  & > .ant-input-affix-wrapper,
  & > .ant-input-affix-wrapper-focused,
  & > .ant-input:hover,
  & > .ant-input-number:hover,
  & > .ant-input-affix-wrapper:hover,
  & > .ant-input:active,
  & > .ant-input-number:active,
  & > .ant-input-affix-wrapper:active,
  & > .ant-input:focus,
  & > .ant-input-number:focus,
  & > .ant-input-affix-wrapper:focus {
    border: none !important;
    box-shadow: none !important;
  }
}

// textarea
.InputWrapper.TextAreaWrapper {
  background-color: @background-tertiary;
  padding: 10px 12px;
  border-radius: 8px;
  border: 1px solid @background-tertiary;
  transition: background-color 0s;

  &:has(textarea:hover),
  &:has(textarea:active),
  &:has(textarea:focus) {
    background-color: @hover-secondary;
    border: 1px solid @hover-secondary;
  }

  &:has(textarea:focus) {
    border: 1px solid @text-complementary;
    background-color: @hover-secondary;
  }
}

.InputWrapper.TextAreaWrapper textarea {
  resize: none;
  transition: background-color 0s;
  padding: 0 12px 0 0;
  .Text1();
  .VerticalScroll();
  border: none !important;
  box-shadow: none !important;

  &:hover,
  &:focus,
  &:active {
    border: none !important;
    box-shadow: none !important;
  }

  &:focus {
    background-color: @hover-secondary;
  }
}


// error
.ErrorInputWrapper.InputWrapper:not(.TextAreaWrapper) > .ant-input,
.ErrorInputWrapper.InputWrapper:not(.TextAreaWrapper) > .ant-input-number,
.ErrorInputWrapper.InputWrapper:not(.TextAreaWrapper) > .ant-input-affix-wrapper {
  border: 1px solid @red-420 !important;
  box-shadow: none !important;
}

.InputWrapper > .ant-input-status-error {
  border: 1px solid @red-420 !important;
  box-shadow: none !important;
}

.InputWrapper.TextAreaWrapper:has(.ant-input-status-error),
.InputWrapper.TextAreaWrapper.ErrorInputWrapper {
  border: 1px solid @red-420 !important;
  box-shadow: none !important;
}

.InputErrorMessage {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  margin-top: 2px;
  .Text1();
  color: @red-420;
}

// size
.XLargeInputWrapper {
  & > .ant-input,
  & > .ant-input-number,
  & > .ant-input-affix-wrapper,
  & > .ant-input-password {
    height: 48px;
    padding: 12px;
    .Text2();
  }
}

.LargeInputWrapper {
  & > .ant-input,
  & > .ant-input-number,
  & > .ant-input-affix-wrapper,
  & > .ant-input-password {
    padding: 10px 12px;
    height: 40px;
    .Text1();
  }
}

// autofill
.ant-input:-webkit-autofill:hover,
.ant-input:-webkit-autofill:focus,
.ant-input:-webkit-autofill:active,
.ant-input:-webkit-autofill {
  -webkit-text-fill-color: @text-primary;
  caret-color: @text-primary;
  // autofill background color workaround
  transition: background-color 600000s ease-in-out 0s;
}