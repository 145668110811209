.ExcelButton {
  background-color: @turquoise-780;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  padding: 6px 16px 6px 12px;
  border-color: transparent;

  span {
    .Button2();
  }

  &:hover {
    background-color: @turquoise-780;
    border-color: transparent !important;
    color: @text-primary !important;
  }
}

.ExcelButtonMobile {
  display: flex;
}