@import "./components/styles";
@import "./WorkersFilter/styles";


.workersTableNotFound {
  transform: translate(0, 50%);
  @media @mobile {
    transform: none;
    margin-top: 88px;
  }
}

.workersMoreBtn {
  display: flex;
  cursor: pointer;
  height: 20px;
  align-items: center;

  svg {
    height: 20px;
  }
}

.workersActionMenu {
  display: flex;
  align-items: center;
  gap: 10px
}

.workersDropdown {
  .ant-dropdown-menu {
    background-color: @background-tertiary;

    .ant-dropdown-menu-item:hover {
      background-color: @hover-secondary;
    }
  }

  .HiddenItem {
    display: none !important;
  }
}

.workersAddBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  height: 40px !important;
  width: 216px !important;
  font-size: 14px !important;
}

.workersNotFound {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  gap: 32px;
}

.workersMobileBtn {
  margin-left: 16px;
}

.workersSpin {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: @background-secondary;
  border-radius: 24px;
  max-height: 647px;
}

.WorkersHeaderButton {
  margin-left: auto;

  @media @mobile {
    margin-left: unset;
  }
}