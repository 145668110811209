// primary
.ButtonWrapper > .ant-btn-primary {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: @text-analogous;
  background-color: @button-primary;
  box-shadow: none;

  &:focus-visible {
    outline: none;
    outline-offset: unset;
  }

  &:not([disabled]) {
    &:hover,
    &:focus-visible,
    &:active {
      color: @text-analogous;
      background-color: @hover-button;
    }
  }

  &[disabled] {
    &,
    &:hover,
    &:focus-visible,
    &:active {
      border: none;
      color: @text-analogous;
      opacity: 0.4;
      cursor: not-allowed;
    }
  }

  &.ant-btn-loading {
    color: @text-analogous;
    background-color: @button-primary;

    &:not([disabled]) {
      &:hover,
      &:focus-visible,
      &:active {
        color: @text-analogous;
        background-color: @hover-button;
      }
    }
  }

  // icon coloring
  & > .ant-btn-icon path {
    fill: currentColor;
  }

  & > .ant-btn-icon.ant-btn-loading-icon {
    & svg,
    & path {
      fill: currentColor;
    }
  }
}

// default
.ButtonDefaultWrapper > .ant-btn-default {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;

  &:focus-visible {
    outline: none;
    outline-offset: unset;
  }

  &[disabled] {
    &,
    &:hover,
    &:focus-visible,
    &:active {
      color: @text-primary;
      opacity: 0.4;
      cursor: not-allowed;
    }
  }

  // icon coloring
  & > .ant-btn-icon path {
    fill: currentColor;
  }

  & > .ant-btn-icon.ant-btn-loading-icon {
    & svg,
    & path {
      fill: currentColor;
    }
  }
}

.ButtonDefaultWrapper.ButtonDefaultSecondaryWrapper > .ant-btn-default {
  border: 1px solid @button-primary;
  color: @text-primary;

  &:not([disabled]) {
    &:hover,
    &:focus-visible,
    &:active {
      border: 1px solid @hover-button;
      color: @hover-button;

      // icon coloring
      & > .ant-btn-icon path {
        fill: currentColor;
      }

      & > .ant-btn-icon.ant-btn-loading-icon {
        & svg,
        & path {
          fill: currentColor;
        }
      }
    }
  }
}

.ButtonDefaultWrapper.ButtonDefaultTertiaryWrapper > .ant-btn-default {
  border: 1px solid @auxiliary-white;
  color: @text-primary;

  &:not([disabled]) {
    &:hover,
    &:focus-visible,
    &:active {
      border: 1px solid @hover-button;
      color: @hover-button;

      // icon coloring
      & > .ant-btn-icon path {
        fill: @hover-button;
      }

      & > .ant-btn-icon.ant-btn-loading-icon {
        & svg {
          fill: @hover-button;
        }

        & path {
          fill: @hover-button;
        }
      }
    }
  }
}

.ButtonDefaultWrapper.ButtonDefaultComplementaryWrapper > .ant-btn-default {
  border: 1px solid @red-420;
  color: @text-primary;

  &:not([disabled]) {
    &:hover,
    &:focus-visible,
    &:active {
      border: 1px solid @red-420;
      color: @red-420;

      // icon coloring
      & > .ant-btn-icon path {
        fill: currentColor;
      }

      & > .ant-btn-icon.ant-btn-loading-icon {
        & svg,
        & path {
          fill: currentColor;
        }
      }
    }
  }
}

// grouped
.GroupedButtonWrapper > .ant-btn-primary {
  width: 43px;
  height: 35px;
  color: @text-primary;
  background-color: @icon-primary;
  box-shadow: none;

  &:not([disabled]) {
    &:hover,
    &:focus-visible,
    &:active {
      color: @text-primary;
      background-color: @icon-primary;
      box-shadow: none;
    }
  }

  &[disabled] {
    &,
    &:hover,
    &:focus-visible,
    &:active {
      color: @text-complementary;
      background-color: @icon-primary;
      border-color: @icon-primary;
      cursor: not-allowed;
      box-shadow: none;
    }
  }
}

.GroupedButtonWrapper.GroupedButtonWrapperError > .ant-btn-primary {
  background-color: @red-420;
  &:not([disabled]) {
    &:hover,
    &:focus-visible,
    &:active {
      background-color: @red-420;
    }
  }

  &[disabled] {
    &,
    &:hover,
    &:focus-visible,
    &:active {
      color: @text-secondary;
      background-color: @red-420;
      border-color: @red-420;
      cursor: not-allowed;
      box-shadow: none;
    }
  }
}

.LeftPositionGroupedButton > .ant-btn-primary {
  border-radius: 12px 0 0 12px;
}

.CenterPositionGroupedButton > .ant-btn-primary {
  border-radius: 0;
}

.RightPositionGroupedButton > .ant-btn-primary {
  border-radius: 0 12px 12px 0;
}

// sizes
.XXLargeButtonWrapper > .ant-btn-primary,
.XXLargeButtonWrapper > .ant-btn-default {
  padding: 16px 24px;
  height: 56px;
  .Button1();
}


.XLargeButtonWrapper > .ant-btn-primary,
.XLargeButtonWrapper > .ant-btn-default {
  padding: 12px 24px;
  height: 48px;
  .Button1();
}

.XLargeButtonWrapper.IconOnlyButtonWrapper > .ant-btn-primary,
.XLargeButtonWrapper.IconOnlyButtonWrapper > .ant-btn-default {
  padding: 12px;
  height: 48px;
  width: 48px;
  .Button1();
}

.LargeButtonWrapper > .ant-btn-primary,
.LargeButtonWrapper > .ant-btn-default {
  padding: 11px 20px;
  height: 40px;
  .Button2();
}

.LargeButtonWrapper.IconOnlyButtonWrapper > .ant-btn-primary,
.LargeButtonWrapper.IconOnlyButtonWrapper > .ant-btn-default {
  padding: 10px;
  height: 40px;
  width: 40px;
}

.MediumButtonWrapper > .ant-btn-primary,
.MediumButtonWrapper > .ant-btn-default {
  padding: 9px 20px;
  height: 36px;
  .Button2();
}

.MediumButtonWrapper.IconOnlyButtonWrapper > .ant-btn-primary,
.MediumButtonWrapper.IconOnlyButtonWrapper > .ant-btn-default {
  padding: 8px;
  height: 36px;
  width: 36px;
}

.SmallButtonWrapper > .ant-btn-primary,
.SmallButtonWrapper > .ant-btn-default {
  padding: 7px 16px;
  height: 32px;
  .Button2();
}

.SmallButtonWrapper.IconOnlyButtonWrapper > .ant-btn-primary,
.SmallButtonWrapper.IconOnlyButtonWrapper > .ant-btn-default {
  padding: 8px;
  height: 32px;
  width: 32px;
}

.ant-btn-icon.ant-btn-icon:not(:last-child) {
  margin-inline-end: 0 !important;
}

