.DashboardTableContent {
  max-height: 400px;
  border-radius: 12px;
  background-color: @background-primary;
  .Text1();

  @media @mobile {
    .VerticalAndHorizontalScroll();
  }
}

.DashboardTableContent .ant-table-container {
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    border-radius: 20px;
  }

  ::-webkit-scrollbar-track {
    display: none;
  }

  ::-webkit-scrollbar-thumb {
    max-height: 56px;
    border-radius: 4px;
    background-color: @separator-tertiary;
  }

  @media @mobile {
    all: unset;
  }
}

.DashboardTableContent .ant-table-thead .ant-table-cell {
  background-color: @background-tertiary;
  padding: 12px 24px 8px 24px;
  z-index: 2;
  vertical-align: top;

  &::before {
    display: none;
  }

  @media @mobile {
    position: sticky;
    top: 0;
  }
}

.DashboardTableContent .ant-table-tbody .ant-table-cell {
  padding: 14px 24px 12px 24px;
}

.DashboardTableContent .ant-table-cell.ant-table-cell-scrollbar {
  box-shadow: none;
}

.DashboardTableHeader {
  display: flex;
  flex-direction: column;
}

.DashboardTableHeaderSubText {
  color: @text-auxiliary;
}