.SwitchWrapper {
  &.ant-switch {
    background-color: @grey-420;

    &:hover {
      background-color: @grey-420;
    }
  }

  &.ant-switch.ant-switch-checked {
    background-color: @icon-secondary;

    &:hover {
      background-color: @icon-secondary;
    }
  }

  &.ant-switch-disabled {
    opacity: 0.4;
  }
}