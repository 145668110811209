.Card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  height: 182px;
  padding: 20px;
  border-radius: 16px;
  background-color: @background-secondary;
  transition: width 0.5s ease-in-out;

  @media @mobile {
    padding: 16px;
    height: 162px;
  }
}

.CardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.CardHeaderTitle {
  .Subtitle2();
  color: @text-primary;
}

.CardHeaderSubtitle {
  .Body2();
  color: @text-complementary;
}

.CardContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.CardMain {
  .Headline2();
  color: @text-primary;
}

.CardDetails {
  .Body2();
  color: @text-secondary;
  align-self: flex-start;

  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media @mobile {
    .Caption();
    color: @text-secondary;
  }
}