.SliderComponentWrapper {
  .ant-slider {
    margin: 0 !important;

    .ant-slider-rail {
      height: 6px !important;
      background-color: @icon-secondary !important;
    }

    .ant-slider-track {
      height: 6px !important;
      background-color: @icon-tertiary !important;
    }

    .ant-slider-handle {
      background: @icon-secondary !important;
      box-sizing: border-box !important;

      &::after {
        width: 14px !important;
        height: 14px !important;
        background-color: @icon-secondary;
        box-shadow: 0 0 0 2px @auxiliary-white !important;
        inset-inline-start: -1px;
        inset-block-start: -1px;
      }

      &:hover::after {
        position: absolute;
        box-shadow: 0 0 0 2px @auxiliary-white !important;
      }
    }
  }

  .ant-slider.ant-slider-disabled {
    .ant-slider-rail {
      background-color: @grey-600 !important;
    }

    .ant-slider-track {
      background-color: @icon-tertiary !important;
    }

    .ant-slider-handle,
    .ant-slider-dot {
      border-color: @auxiliary-white !important;
    }

    .ant-slider-handle::after {
      background-color: @grey-600 !important;
      box-shadow: 0 0 0 2px @auxiliary-white !important;
    }
  }
}
