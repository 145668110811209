@import "./SmsMailingCreateStep/styles.less";
@import "./SmsMailingCreatePreview/styles.less";
@import "./SmsMailingCreateModal/styles.less";

.SmsMailingCreateScreenContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: @background-secondary;
  border-radius: 24px;
}

.SmsMailingCreateScreenForm {
  flex-grow: 1;
  padding: 32px 52px 40px 24px;
}

.SmsMailingCreateScreenForm .ant-form-item {
  margin-bottom: 0;
}

.SmsMailingCreateScreenFormItem .ant-form-item-label {
  padding: 0 0 6px;
}

.SmsMailingDisplayNone {
  display: none !important;
}

.SmsMailingCreateScreenFormRadioGroup {
  margin-bottom: 16px;
}

.SmsMailingCreateScreenFormRadioGroup .RadioGroupOption {
  margin-bottom: 16px;
  padding-bottom: 0;
  height: unset;

  & .LargeRadioWrapper {
    height: unset;
  }
}

.SmsMailingCreateScreenFormMacrosContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  width: 444px;

  @media @mobile {
    width: 100%;
  }
}

.SmsMailingCreateScreenFormPromptItem {
  width: 444px;

  @media @mobile {
    width: 100%;
  }
}

.SmsMailingCreateScreenFormItem label.ant-form-item-required::before {
  display: none !important;
}

.SmsMailingCreateScreenFormItem:has(.SmsMailingCreateScreenFormMacrosAddress),
.SmsMailingCreateScreenFormItem:has(.SmsMailingCreateScreenFormMacrosPhone) {
  grid-column: span 2;
}

.SmsMailingCreateScreenFormItem:has(.SmsMailingCreateScreenFormMacrosDate),
.SmsMailingCreateScreenFormItem:has(.SmsMailingCreateScreenFormMacrosTime) {
  grid-column: span 1;
}

.SmsMailingCreateScreenFormSubmit {
  margin-top: 40px;

  @media @mobile {
    margin-top: 32px;
  }
}
