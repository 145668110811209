.NewOrderModalContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex-shrink: 0;
}

.NewOrderModalHeader {
  display: block;
  .Headline1();
}

.NewOrderModalProductGroupContainer {
  display: flex;
  width: 1050px;
  flex-direction: column;
  align-items: flex-start;
}

.NewOrderModalProductGroupHeader {
  display: flex;
  padding: 12px 0;
  align-self: stretch;
}

.NewOrderModalProductGroupHeaderText {
  .Headline2();
  color: @text-auxiliary;
}

.NewOrderModalProductGroupItemContainer {
  display: flex;
  gap: 24px 64px;
  flex-wrap: wrap;
}

.NewOrderModalProductGroupDivider {
  width: 100%;
  height: 1px;
  background-color: @separator-tertiary;
  margin: 32px 0;
}

.NewOrderModalFooterPlaceholder {
  width: 100%;
  min-height: 120px;
}

.NewOrderModalFooterWrapper {
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  width: 1172px;
  margin-left: -56px;
  padding: 32px 789px 32px 56px;
  border-top: 1px solid @separator-tertiary;
  // todo нужен цвет из типографии
  background: rgba(16, 48, 84, 0.85);
  backdrop-filter: blur(6px);
  z-index: 2;
}
