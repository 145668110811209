.PageHeaderContainer {
  display: flex;
  flex-direction: row;
  gap: 16px;
  max-width: 1400px;

  @media @mobile {
    flex-direction: column;
    padding: 22px 16px 0;
  }

  & svg > path {
    fill: @text-secondary;
  }
}

.PageHeader {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 8px;
}

.PageHeaderTitle {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;
}

.PageHeaderTitleTextWrapper {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 8px;
}

.PageHeaderTitleTextWrapper > .TooltipComponentTargetWrapper {
  margin-top: 4px;

  @media @mobile {
    margin-top: 2px;
  }
}

.PageHeaderTitleTextWrapper > .TooltipComponentTargetWrapper.MacOSTooltipComponentTargetWrapper {
  margin-top: 0;

  @media @mobile {
    margin-top: 0;
  }
}

.PageHeaderReturnIcon {
  cursor: pointer;
  margin-right: -4px;
}

.PageHeaderTitleText {
  .Headline1();

  @media @mobile {
    .Headline2();
  }
}

.PageHeaderSubtitleText {
  margin-left: -4px;
  .Headline1();
  color: @text-secondary;

  @media @mobile {
    .Headline2();
    color: @text-secondary;
  }
}

.PageHeaderSub {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.DateTimeContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.DateText {
  .Text2();

  @media @mobile {
    .Caption();
  }
}

.TimeText {
  .Text2();
  color: @text-secondary;

  @media @mobile {
    .Caption();
    color: @text-secondary;
  }
}