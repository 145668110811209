.CashboxTableContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.CashboxTableHeaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.CashboxTableHeader {
  display: flex;
  align-items: center;
  gap: 8px;

  .Headline2();

  .TooltipComponentTargetWrapper {
    margin: unset;
  }
}

.CashboxTableHeaderIcon {
  display: flex;
  align-items: center;
}

.CashboxTableDivider {
  width: 100%;
  height: 1px;
  background-color: @separator-tertiary;
  margin: 12px 0 24px 0;
}

.CashboxTableIcon {
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &.-rotate {
    transform: rotate(180deg);
  }
}

.CashboxTableInside {
  display: flex;
  flex-direction: column;

  .CashboxTableInsideRow {
    display: inline-grid;
    padding: 16px 16px 16px 32px;
    width: 100%;
    .Text1();

    &:hover {
      background-color: @background-tertiary;
    }
  }

  .CashboxTableInsideHallRow {
    background-color: @background-tertiary;
    border-radius: 4px;
  }

  .CashboxTableInsideItem {
    display: flex;
    align-items: center;
  }
}

.CashboxTableInside > div {
  display: flex;
  flex-direction: column;
}

.NewCashboxTableInside {
  .CashboxTableInsideItem:first-child {
    padding-left: 0;
  }
  .CashboxTableInsideItem:nth-child(2) {
    padding-left: 36px;
  }
  .CashboxTableInsideItem:nth-child(3) {
    padding-left: 38px;
  }
  .CashboxTableInsideItem:nth-child(4) {
    padding-left: 40px;
  }
}

.NewCashboxTableInside .CashboxTableInsideRow {
  grid-template-columns: 20% 20% 20% 40%;
}

.SZHCashboxTableInside {
  .CashboxTableInsideItem:first-child {
    padding-left: 0;
  }
  .CashboxTableInsideItem:nth-child(2) {
    padding-left: 36px;
  }
  .CashboxTableInsideItem:nth-child(3) {
    padding-left: 38px;
  }
  .CashboxTableInsideItem:nth-child(4) {
    padding-left: 40px;
  }
}

.SZHCashboxTableInside .CashboxTableInsideRow {
  grid-template-columns: 20% 20% 20% 40%;
}

.UKMCashboxTableInside {
  .CashboxTableInsideItem:first-child {
    padding-left: 0;
  }
  .CashboxTableInsideItem:nth-child(2) {
    padding-left: 22%;
  }
  .CashboxTableInsideItem:nth-child(3) {
    padding-left: 22%;
  }
  .CashboxTableInsideItem:nth-child(4) {
    padding-left: 22%;
  }
  .CashboxTableInsideItem:nth-child(5) {
    padding-left: 22%;
  }
  .CashboxTableInsideItem:nth-child(6) {
    padding-left: 22%;
  }
  .CashboxTableInsideItem:nth-child(7) {
    padding-left: 22%;
  }
}

.UKMCashboxTableInside .CashboxTableInsideRow {
  grid-template-columns: 16% 14% 14% 14% 14% 14% 14%;
}

.OldCashboxTableInside {
  .CashboxTableInsideItem:first-child {
    padding-left: 0;
  }
  .CashboxTableInsideItem:nth-child(2) {
    padding-left: 32px;
  }
  .CashboxTableInsideItem:nth-child(3) {
    padding-left: 30px;
  }
  .CashboxTableInsideItem:nth-child(4) {
    padding-left: 30px;
  }
  .CashboxTableInsideItem:nth-child(5) {
    padding-left: 30px;
  }
  .CashboxTableInsideItem:nth-child(6) {
    padding-left: 30px;
  }
}

.OldCashboxTableInside .CashboxTableInsideRow {
  grid-template-columns: 15% 17% 17% 17% 17% 17%;
}

.CashboxTableInsideItem:first-child {
  gap: 12px;
}

.CashboxTableColumnHeader {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 6px;
}

.CashboxTableColumnHeaderIcon {
  width: 16px;
  height: 16px;
}
