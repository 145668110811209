@import "containers/Dashboard/DashboardCharts/styles.less";
@import "containers/Dashboard/DashboardFilter/styles.less";
@import "containers/Dashboard/DashboardItem/styles.less";
@import "containers/Dashboard/DashboardTable/styles.less";
@import "containers/Dashboard/DashboardTable/DashboardTableCard/styles.less";


.ComponentDashboardWrapper {
  max-width: 1400px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-x: auto;
  background-color: @background-secondary;
  border-radius: 24px;
  padding: 24px;
  min-height: 260px;

  @media @mobile {
    width: 100vw;
    padding: 16px;
    border-radius: 16px;
    min-height: 176px;
  }
}

.ComponentDashboardHeader {
  display: flex;
  align-items: center;

  .ComponentDashboardHeaderSubText {
    .TextBold();
    color: @text-secondary;
    margin-left: 4px;
    margin-right: 4px;
  }

  .ComponentDashboardHeaderMainText {
    .TextBold();
  }
}

.ComponentDashboardHeaderSkeleton {
  height: 20px;
}

.ComponentDashboardContent {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  gap: 16px;
  overflow-y: hidden;
}


.ComponentDashboardDetailsWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: @background-secondary;
  padding: 24px;
  border-radius: 24px;

  @media @mobile {
    width: 100vw;
    padding: 24px 12px;
    border-radius: 16px;
  }
}

.ComponentDashboardDetailsWrapperHidden {
  display: none;
}

.ComponentDashboardDetailsSpinner {
  min-height: 458px;

  @media @mobile {
    width: 100vw;
    min-height: unset;
  }
}

.ComponentDashboardDetailsHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ComponentDashboardDetailsHeaderText {
  .Headline2();
}

.ComponentDashboardDetailsHeaderMobile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
}

.ComponentDashboardDetailsHeaderTextMobile {
  .Headline3();
}

.ComponentDashboardDetailsContainerMobile {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
