.Switcher {
  display: flex;
  max-width: 340px;
  background-color: transparent;
  border-radius: 12px;
  border: 1px solid @separator-primary;
  color: @text-secondary;
  padding: 2px 2px;
  min-height: 38px;
  align-self: flex-end;

  &__Item {
    .Button1();
    border-radius: 12px;
    min-width: 170px;
    max-width: 170px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.4s ease-in-out;

    &-Active {
      color: @text-primary;
      background-color: @hover-secondary;
      padding: 7px 10px;
      border-radius: 10px;
    }

    &-Disabled {
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.4;
    }
  }
}