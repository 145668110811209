.HallPickerModalContainer {
  gap: 32px;
  border-radius: 16px;
  width: 728px !important;
  max-height: 55vh !important;
  padding: 32px;

  @media @mobile {
    max-width: calc(100vw - 32px);
    max-height: 60vh;
    padding: 24px 32px;
  }
}

.HallPickerModalHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 32px;

  @media @mobile {
    flex-direction: column;
    justify-content: center;
    gap: 16px;
  }
}

.HallPickerModalTitle {
  .Headline2();
}

.HallPickerModalSearch {
  @media @mobile {
    width: 100%
  }
}

.HallPickerModalSearch .ant-input-affix-wrapper {
  width: 280px;

  @media @mobile {
    margin-left: 0;
    width: 100%;
  }
}

.HallPickerModalContent {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
  width: 100%;
  padding: 0 8px 0 32px;
  .VerticalScrollGutter();

  @media @mobile {
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
  }
}

.HallPickerModalItem {
  height: 56px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  background-color: @background-tertiary;
  color: @text-primary;

  &:hover {
    background-color: @button-primary;
    color: @text-analogous;
  }

  @media @mobile {
    height: 48px;
  }

  .HallPickerModalItemLabel {
    .Button1();
  }
}