.TableComponent.ant-table-wrapper {
  .ant-table {
    // head
    .ant-table-thead {
      height: 64px;

      .ant-table-cell {
        background-color: @background-tertiary;
        border-bottom: transparent;
        padding-top: 24px;
        padding-bottom: 24px;

        .TableHeader();
      }

      .ant-table-cell::before {
        background-color: transparent !important;
      }

      tr > *:first-child {
        padding-left: 32px;
      }

      tr > *:last-child {
        padding-right: 32px;
      }

      tr:first-child > *:first-child {
        border-start-start-radius: 24px;
      }

      tr:first-child > *:last-child {
        border-start-end-radius: 24px;
      }
    }

    // body
    .ant-table-tbody {
      tr > *:first-child {
        padding-left: 32px;
      }

      tr > *:last-child {
        padding-right: 32px;
      }

      .ant-table-cell.ant-table-row-expand-icon-cell {
        padding-right: 0;
      }

      .ant-table-cell {
        height: 51px;
        background-color: @background-secondary;
        border-bottom: 1px solid @separator-tertiary;

        .Text1();

        .-minus {
          color: @red-420;
        }

        .-secondary {
          color: @text-secondary;
        }

        .-totalRow {
          font-weight: 700;
        }
      }

      .ant-table-cell-row-hover {
        background-color: @background-tertiary;
      }

      .ant-table-expanded-row .ant-table-cell {
        padding: 0;
      }
    }

    .ant-table-content {
      .TableHorizontalScroll();
    }
  }

  // pagination
  ul.ant-pagination.ant-table-pagination {
    background-color: @background-tertiary;
    padding: 12px 24px;
    margin: unset;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;

    .ant-pagination-item-active {
      background-color: transparent;

      a {
        color: @text-primary;
      }
    }

    .ant-pagination-options {
      margin-left: auto;

      svg path {
        fill: @icon-complementary;
      }

      @media @mobile {
        display: inline-block;
      }
    }

    .ant-select-focused.ant-select:not(.ant-select-disabled) .ant-select-selector {
      box-shadow: none;
      border-color: @separator-primary;
    }

    .ant-select-selector {
      background-color: transparent;
      border-color: @separator-primary;
      padding: 0 14px;
      border-radius: 8px;
      cursor: pointer;
      .Text1();

      &:hover {
        border-color: @separator-primary;
      }

      .ant-select-selection-item {
        padding-inline-end: 28px;
        color: @text-primary;
      }
    }

    .ant-select-arrow {
      color: @icon-complementary;
    }

    .ant-select-dropdown {
      padding: 0;
    }

    .ant-pagination-disabled .ant-pagination-item-link {
      color: @grey-600;
    }
  }
}