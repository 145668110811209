.SelectWrapper {
  .ant-select {
    width: 100%;

    .ant-select-arrow {
      transform: rotate(0deg);
      transition: transform 500ms ease;
    }
  }

  .ant-select-selector {
    display: flex;
    align-items: center;
    border-radius: 8px;
    background-color: @background-tertiary !important;
    border: 1px solid @background-tertiary !important;
    box-shadow: none !important;
    padding: 12px 12px 12px 14px !important;

    &:not(.ant-select-disabled):hover,
    &:not(.ant-select-disabled):active {
      background-color: @hover-secondary !important;
      border: 1px solid @hover-secondary !important;
      box-shadow: none !important;

      .ant-select-selection-item {
        color: @text-primary !important;
        background-color: transparent;
      }
    }

    &:not(.ant-select-disabled):focus,
    &:not(.ant-select-disabled):focus-visible {
      background-color: @hover-secondary !important;
      border: 1px solid @hover-primary !important;
      box-shadow: none !important;

      .ant-select-selection-item {
        color: @text-primary !important;
        background-color: transparent;
      }
    }
  }

  .ant-select-selection-placeholder {
    color: @text-complementary !important;
  }

  .ant-select-arrow {
    background-color: transparent !important;

    & > svg > path {
      fill: @text-primary;
    }
  }

  .ant-select-open {
    &:not(.ant-select-disabled) .ant-select-selector {
      &,
      &:hover {
        background-color: @hover-secondary !important;
        border: 1px solid @hover-primary !important;
      }

      .ant-select-selection-item {
        color: @text-primary !important;
        background-color: transparent !important;
      }
    }

    .ant-select-arrow {
      background-color: transparent !important;
      transform: rotate(180deg);
      transition: transform 500ms ease;
    }
  }

  &.ant-select-disabled {
    opacity: 0.5;
  }
}

.SelectComponent.LargeSelectWrapper .ant-select-selector {
  height: 40px !important;

  .ant-select-selection-placeholder,
  .ant-select-selection-item,
  .ant-select-dropdown .ant-select-item-option .ant-select-item-option-content {
    .Text1();
  }
}

.SelectComponent.XLargeSelectWrapper .ant-select-selector {
  height: 46px !important;

  .ant-select-selection-placeholder,
  .ant-select-selection-item,
  .ant-select-dropdown .ant-select-item-option .ant-select-item-option-content {
    .Text2();
  }
}

.SelectGreyWrapperDisabled {
  opacity: 1;
}

.ErrorTreeSelectWrapper,
.ErrorSelectWrapper {
  .ErrorText {
    font-size: 10px;
    color: @red-420;
    margin-top: 2px;
  }

  .ant-select-selector {
    border-color: @red-420 !important;
  }
}

.ant-select-dropdown {
  border: none;
  box-shadow: none;
  border-radius: 8px !important;
  margin-top: -6px !important;
  background-color: @background-tertiary;

  .ant-select-item-option {
    padding: 8px 20px;
    background-color: @background-tertiary;

    &:not(.ant-select-item-option-disabled) {
      color: @text-primary;
    }

    &.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
      font-weight: 400 !important;
      color: @text-primary;
      background-color: @hover-tertiary;
    }

    &-selected {
      font-weight: 400 !important;
      color: @text-primary !important;
      background-color: @hover-tertiary !important;
    }
  }

  .rc-virtual-list-scrollbar.rc-virtual-list-scrollbar-vertical,
  .rc-virtual-list-scrollbar.rc-virtual-list-scrollbar-vertical .rc-virtual-list-scrollbar-thumb {
    display: none !important;
  }
}

.ant-select-selection-overflow-item > .ant-select-selection-item {
  background: @text-primary !important;
  border-radius: 8px !important;
  padding: 0 8px !important;
}
