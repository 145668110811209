.AddToCartCounterGroupedButtonText {
  .Headline3();
}

.AddToCartQuantity {
  width: 43px;
  background-color: @icon-primary;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AddToCartQuantityText {
  .Subtitle2();
}

.ant-btn-primary[disabled] .AddToCartCounterGroupedButtonText {
  color: @text-secondary;
}