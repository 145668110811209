.DashboardFilterGmtWrapper {
  margin-top: 10px;

  @media @mobile {
    max-height: 60px;
    .VerticalScroll();
  }
}

.DashboardFilterShiftSliderWrapper {
  margin-top: 10px;
}

.DashboardFilterGmtHourWrapper {
  display: flex;
  flex-direction: row;
  padding-top: 14px;
}

.DashboardFilterGmtHour {
  .Caption();
}

.DashboardFilterGmtHourMoscow {
  .Caption();
  color: @text-secondary;
  padding-left: 4px;
}
