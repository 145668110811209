@import "ChangePhoneFormModal/styles.less";
@import "ChangePhoneStatusModal/styles.less";
@import "ChangePhoneWarningModal/styles.less";
@import "ChangePhoneFilter/styles.less";


.ChangePhoneHeaderButton {
  margin-left: auto;

  @media @mobile {
    margin-left: unset;
  }
}

.ChangePhoneMainModalHeader {
  .Headline1();

  @media @mobile {
    .Headline3();
  }
}

.ChangePhoneSecondaryModalHeader {
  .Headline2();

  @media @mobile {
    .Headline3();
  }
}

.ChangePhoneAddRequestForm > .ant-form-item {
  margin-bottom: 24px;

  @media @mobile {
    margin-bottom: 16px;
  }
}

.ChangePhoneAddRequestForm > .ant-form-item:last-child {
  margin-bottom: 0;
}

.ChangePhoneModal .ant-form-item-required::before {
  display: none !important;
}

.ChangePhoneSpin {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-radius: 24px;
  max-height: 647px;
}

