.OrderCheckoutModalContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.OrderCheckoutModalHeader {
  display: block;
  .Headline1();
}

.OrderCheckoutModalContent {
  display: flex;
  justify-content: space-between;
  gap: 112px;
}

.OrderCheckoutModalForm {
  display: flex;
  height: 824px;
  flex-direction: column;
  gap: 18px;
  flex: 0 1 50%;
}

.OrderCheckoutModalFormBlock {
  display: flex;
  gap: 32px;
  flex-direction: column;
}

.OrderCheckoutModalSubtitle {
  display: flex;
  width: 327px;
  padding-top: 12px;
  align-items: flex-start;
  margin-bottom: -14px;
  .Subtitle2();
}

.OrderCheckoutModalOrderDetails {
  display: flex;
  width: 382px;
  flex-direction: column;
  align-items: flex-start;
}

.OrderCheckoutModalProductPreviewContainer {
  .VerticalScroll();
}

.OrderCheckoutModalFooter {
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  width: 1172px;
  margin-left: -56px;
  padding: 32px 789px 32px 56px;
  border-top: 1px solid @separator-tertiary;
  // todo нужен цвет из типографии
  background: rgba(16, 48, 84, 0.85);
  backdrop-filter: blur(6px);
  z-index: 2;
}

.OrderCheckoutModalFooterButtonContainer {
  display: flex;
  align-items: flex-start;
  gap: 32px;
}

.CheckoutOrderErrorMessage {
  font-family: 'Lato-400', sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: @red-420;
  margin-top: -12px;
  padding: 4px 0;

  & > svg {
    margin-right: 5px;
  }
}

.OrderCheckoutSuccessModalContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.OrderCheckoutSuccessModalImage {
  height: 312px;
  width: 319px
}

.OrderCheckoutSuccessModalHeader {
  margin-top: 64px;
  width: 250px;
  text-align: center;
  .Headline2();
}

.OrderCheckoutSuccessModalText {
  margin-top: 16px;
  width: 290px;
  text-align: center;
  .Subtitle2();
}

.OrderCheckoutSuccessModalButtonWrapper {
  margin-top: 92px;
}
