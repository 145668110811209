@import "antd/dist/reset.css";

@import "theme.less";

@import "containers/Authentication/styles.less";
@import "containers/HallPicker/styles.less";
@import "containers/MainMenu/styles.less";
@import "containers/Dashboard/styles.less";
@import "containers/Order/styles.less";
@import "containers/PageLayout/styles.less";
@import "containers/Workers/styles.less";
@import "containers/Cashbox/styles.less";
@import "containers/GamesReport/styles.less";
@import "containers/Attendance/styles.less";
@import "containers/ChangePhone/styles.less";
@import "containers/SmsMailing/styles.less";

@import "components/Input/styles.less";
@import "components/Button/styles.less";
@import "components/Modal/styles.less";
@import "components/HallListFilter/styles.less";
@import "components/Radio/styles.less";
@import "components/Slider/styles.less";
@import "components/Checkbox/styles.less";
@import "components/Spin/styles.less";
@import "components/Tooltip/styles.less";
@import "components/FilterOption/styles.less";
@import "components/Select/styles.less";
@import "components/DatePicker/styles.less";
@import "components/ExcelButton/styles.less";
@import "components/SelectHour/styles.less";
@import "containers/PageLayout/PageHeader/styles.less";
@import "components/TabSwitcher/styles.less";
@import "components/EmptyScreen/styles.less";
@import "components/StatusLabel/styles.less";
@import "components/NotFound/styles.less";
@import "components/Card/styles.less";
@import "components/Notification/styles.less";
@import "components/Tabs/styles.less";
@import "components/Table/styles.less";
@import "components/Switch/styles.less";
