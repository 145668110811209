.DashboardChartsWrapper {
  background-color: @background-secondary;
}

.highcharts-credits {
  display: none !important;
}

.DashboardChartTooltip {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.DashboardChartTooltipMainText {
  .Body2();
  color: @text-tertiary;
}

.DashboardChartTooltipSubText {
  .Caption();
  color: @text-secondary;
}