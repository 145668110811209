.PickerWrapper {
  .ant-picker {
    border-radius: 8px;
    background-color: @background-tertiary;
    border: 1px solid @background-tertiary;
    width: 100%;

    &:hover {
      background: @hover-secondary;
    }

    &-suffix {
      margin-left: auto;

      & > svg > path {
        fill: @text-complementary;
      }
    }

    &-input {
      caret-color: transparent;
    }
  }

  .ant-picker:has(.ant-picker-input input:not(:placeholder-shown)) .ant-picker-suffix svg path {
    fill: @text-primary;
  }

  .ant-picker-focused.ant-picker:hover,
  .ant-picker-focused {
    border: 1px solid @hover-primary;
    box-shadow: none !important;
    background-color: @hover-secondary;
  }

  .ant-picker .ant-picker-disabled:hover {
    border: 1px solid @hover-primary;
  }

  .ant-picker-range .ant-picker-active-bar {
    display: none;
  }
}

.PickerWrapper.LargePickerWrapper {
  .ant-picker {
    padding: 10px 12px;
    height: 40px;
  }

  .ant-picker .ant-picker-input > input::placeholder {
    .Text1();
  }
}

.PickerWrapper.XLargePickerWrapper {
  .ant-picker {
    padding: 12px;
    height: 48px;
  }

  .ant-picker .ant-picker-input > input::placeholder {
    .Text2();
  }
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: @text-primary !important;
}

.ant-picker-cell:not(.ant-picker-cell-in-view) .ant-picker-cell-inner {
  color: @background-tertiary;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: @background-tertiary;
}

.ant-picker .ant-picker-input-placeholder > input {
  color: @text-primary !important;
}

.ant-picker .ant-picker-input > input::placeholder {
  color: @text-complementary !important;
}

.ant-picker-dropdown .ant-picker-header > button {
  color: @icon-tertiary;
}

.ant-picker-dropdown .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after {
  border-color: @background-tertiary !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
  border: none !important;
}

.ant-picker-date-panel {
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
    border: none !important;
    background: @icon-secondary !important;
    gap: 0;
    height: 34px !important;
    inset-inline-start: 0;
  }

  .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-start-single.ant-picker-cell-range-hover-start.ant-picker-cell-selected:after {
    border-top-left-radius: 17px;
    border-bottom-left-radius: 17px;
  }

  .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover-end {
    border-radius: 17px;

    &:before {
      width: 100%;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-hover {
    color: @text-analogous;
  }

  .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover-start.ant-picker-cell-range-hover-end {
    &:before {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      height: 34px !important;
      width: 100%;
    }
  }

  .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child:after {
    border-top-right-radius: 17px;
    border-bottom-right-radius: 17px;
  }

  .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child:after {
    border-top-left-radius: 17px;
    border-bottom-left-radius: 17px;
  }

  .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-hover-end {
    color: @text-analogous;

    &:before {
      background: @icon-secondary !important;
      height: 34px !important;
      border-radius: 17px;
    }

    &:after {
      border-top-right-radius: 17px;
      border-bottom-right-radius: 17px;
      color: @text-analogous !important;
    }
  }
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: @icon-tertiary !important;
}

.ant-picker-header button {
  font-weight: bold !important;
}

.ant-picker-dropdown .ant-picker-panel-container {
  background-color: @background-tertiary;
  .Shadow();
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: none;
}

.ant-picker-today-btn {
  color: @text-primary !important;
}

.ant-picker-today-btn:active,
.ant-picker-today-btn:hover {
  color: @icon-secondary !important;
}

.PickerWrapper .ant-picker-disabled {
  opacity: 0.5;
}

.ant-picker-dropdown {
  z-index: 9999 !important;
}

.ant-picker-dropdown {
  .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
    background: @icon-secondary !important;
    opacity: 0.8 !important;
  }
}

.ant-picker-dropdown {
  .ant-picker-cell-in-view.ant-picker-cell-in-range:first-child::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):first-child::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):first-child::before {
    border-top-left-radius: 17px;
    border-bottom-left-radius: 17px;
  }
}

.ant-picker-dropdown {
  .ant-picker-cell-in-view.ant-picker-cell-in-range:not(.ant-picker-cell-selected):last-child::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-selected):last-child::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-selected):last-child::before {
    border-top-right-radius: 17px;
    border-bottom-right-radius: 17px;
  }
}

.ErrorPickerWrapper .ant-picker,
.ErrorRangePickerWrapper .ant-picker-range {
  border-color: @red-420 !important;
}

.DatePickerWrapperError > .ant-picker,
.TimePickerWrapperError > .ant-picker {
  border: 1px solid @red-420 !important;
}

.ant-picker-range {
  display: flex !important;
  flex-direction: row !important;
}

.ant-picker-range > .ant-picker-input {
  display: inline-block !important;
  max-width: 80px;
}

.ant-picker-range > .ant-picker-input input {
  text-align: center !important;
}

.DatePickerSeparatorWrapper {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: @text-complementary;
}

@media screen and @mobile {
  .ant-picker-panels {
    flex-direction: column !important;
  }
}

.ant-picker-dropdown .ant-picker-cell .ant-picker-cell-inner {
  min-width: 34px;
  height: 34px;
  line-height: 34px;
  border-radius: 17px;
  background-color: inherit;
}

.ant-picker-dropdown .ant-picker-cell:not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-today):not(.ant-picker-cell-range-hover-end):not(.ant-picker-cell-range-hover-start).ant-picker-cell-in-view:hover .ant-picker-cell-inner {
  background-color: @background-tertiary;
}

.ant-picker-dropdown .ant-picker-cell.ant-picker-cell-today .ant-picker-cell-inner {
  color: @text-analogous;
  background-color: @icon-tertiary;
}

.ant-picker-dropdown .ant-picker-cell.ant-picker-cell-today:hover .ant-picker-cell-inner {
  color: @text-analogous;
  background-color: @icon-tertiary !important;
}

// range picker
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner {
  border-radius: 17px;
  background-color: @icon-secondary;
}

.ant-picker-dropdown .ant-picker-cell::before {
  height: 34px;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner {
  border-radius: 17px;
  background-color: @icon-secondary;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: @text-analogous !important;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range .ant-picker-cell-inner {
  color: @text-analogous !important;
}

.ant-picker-dropdown .ant-picker-content th {
  color: @text-complementary;

  &:nth-child(n + 6) {
    color: @red-420;
  }
}

.ant-picker-clear:has(.PickerReset) {
  opacity: 1;
  background: none;
  top: -50%;
}

.PickerReset {
  .Subtitle1();
  cursor: pointer;
}

// time picker
.ant-picker .ant-picker-input .ant-picker-suffix span svg {
  fill: @text-complementary;
}

.ant-picker.ant-picker-status-success .ant-picker-input .ant-picker-suffix span svg {
  fill: @icon-complementary;
}

.ant-picker-dropdown {

  .ant-picker-content {
    padding: 12px 6px !important;
  }

  .ant-picker-time-panel {
    padding-top: unset !important;
  }

  .ant-picker-time-panel-column {
    width: unset;
    margin: unset;
    .VerticalScroll();

    &:not(:first-child) {
      border-inline-start: none;
    }

    &::after {
      display: none !important;
    }

    .ant-picker-time-panel-cell {
      height: 34px !important;
      width: 34px !important;
      margin-inline: unset !important;
    }

    .ant-picker-time-panel-cell.ant-picker-time-panel-cell-selected {
      border-radius: 40px;
      background-color: @icon-secondary;
      color: @text-analogous;

      & > .ant-picker-time-panel-cell-inner {
        border-radius: 40px;
        background-color: @icon-secondary;
        color: @text-analogous;
      }
    }

    .ant-picker-time-panel-cell-inner {
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      height: 34px !important;
      width: 34px !important;
      padding-inline-start: unset !important;
      transition: unset !important;
    }

  }
}

.ant-picker-footer {
  border-top: none !important;
  line-height: unset !important;

  & .ant-picker-ranges {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 12px 12px;
    line-height: unset;

    & > .ant-picker-ok {
      margin-inline-start: unset;
    }

    & > .ant-picker-ok > button.ant-btn.ant-btn-primary.ant-btn-sm {
      border: 1px solid @button-primary;
      color: @text-primary;
      background-color: @background-tertiary;
      box-shadow: none;
      padding: 3px 12px;
      border-radius: 8px;
      text-align: center;
      font-family: 'Gilroy-500', sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;

      &[disabled] {
        &,
        &:hover,
        &:focus,
        &:active {
          color: @text-primary;
          opacity: 0.4;
          cursor: not-allowed;
        }
      }

      &:not([disabled]) {
        &:hover,
        &:focus,
        &:active {
          border: 1px solid @hover-button;
          color: @hover-button;
        }
      }
    }
  }
}

.ant-picker-dropdown .ant-picker-header {
  border-bottom: 1px solid @separator-primary;
}

.ant-picker-dropdown .ant-picker-header-view {
  color: @text-primary;

  & button:hover {
    color: @hover-button !important;
  }
}

.ant-picker-dropdown .ant-picker-cell-disabled {
  color: @grey-540;
  background-color: @background-tertiary;

  &::before {
    background: unset;
  }
}

// showNow prop does not work now
.ant-picker-dropdown:has(.ant-picker-date-panel) .ant-picker-footer {
  display: none !important;
}