@import "SmsMailingCreateScreen/styles.less";
@import "SmsMailingCardModal/styles.less";
@import "SmsMailingFilter/styles.less";

.SmsMailingHeaderButton {
  margin-left: auto;

  @media @mobile {
    margin-left: unset;
  }
}

.SmsMailingCardListContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(332px, 1fr));
  gap: 24px;
}

.SmsMailingDisplayNone {
  display: none;
}
