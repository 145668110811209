.TooltipComponentTargetWrapper {
  display: flex;
  align-self: center;

  @media @mobile {
    width: 20px;
  }
}

.TooltipComponentTargetWrapper:hover {
  cursor: pointer;
}

.TooltipComponentWrapper .ant-popover-content .ant-popover-inner {
  border-radius: 12px;
  padding: 12px 16px !important;
  background-color: @separator-tertiary;
  .Shadow1();

  .ant-popover-inner-content {
    max-width: 320px;
    .Overline();
  }
}

.TooltipComponentWrapper {
  .ant-popover-arrow-content::before {
    background-color: @separator-tertiary;
  }
}
