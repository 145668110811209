.LoginPageWrapper {
  width: 100vw;
  height: 100vh;
  background-color: @background-primary;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.LoginFormWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin: auto;
  padding: 32px;
  border-radius: 16px;
  background-color: @background-secondary;
  width: 480px;
  z-index: 2;

  @media @mobile {
    width: unset;
    margin: auto 16px;
  }
}

.LoginPageLogo {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin-top: calc(50vh - 340px);
  z-index: 1;

  @media @mobile {
    margin-top: calc(50vh - 295px);
  }
}

.LoginPageIcon {
  width: 64px;
  height: 64px;

  @media @mobile {
    width: 48px;
    height: 48px;
  }
}

.LoginPageHeader {
  .Headline3();
}

.LoginFormLabel {
  .Headline2();
}

.LoginForm {
  width: 100%;

 & .LoginFormItem.ant-form-item {
   margin-bottom: 16px;

   & .ant-form-item-label {
     padding-bottom: 6px;
   }
 }

  & .LoginFormItemButton.ant-form-item {
    display: flex;
    justify-content: center;
    margin: 32px 0 0 0;
  }
}

.LoginFormSubmit {
  min-width: 172px;
}