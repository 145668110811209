// todo: сделать эту стилизацию общей для всех фильтров
.ChangePhoneFilterContentContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  .VerticalScrollInvisible();
  max-height: calc(100vh - 175px);
}

.ChangePhoneFilterSearch {
  @media @mobile {
    align-self: center;
  }
}