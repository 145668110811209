.SmsMailingCreateModalContent {
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  gap: 24px;
}

.SmsMailingCreateModalItem {
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  gap: 6px;
}

.SmsMailingCreateModalItemLabel {
  .TableHeader();
  color: @text-secondary;
}

.SmsMailingCreateModalItemValue {
  .Text2();
}

.SmsMailingCreateModalSumWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

.SmsMailingCreateModalSumLabel {
  .Subtitle1();
}

.SmsMailingCreateModalSumValue {
  .Headline3();
}

.SmsMailingCreateDeleteModalText {
  .Text2();
}

.SmsMailingCreateModalError {
  .Text1();
  color: @red-420;
}