.OrderFilterContentContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  .VerticalScrollInvisible();
  max-height: calc(100vh - 175px);
}

.OrderListFilterSearch {
  @media @mobile {
    align-self: center;
  }
}

.OrderFilterContentContainer .rc-virtual-list-holder-inner .ant-select-item.ant-select-item-option .ant-select-item-option-content {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;

  &:before {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 8px;
  }
}

.OrderFilterContentContainer .rc-virtual-list-holder-inner .ant-select-item.ant-select-item-option {

  &:first-child .ant-select-item-option-content::before {
    visibility: hidden;
  }

  &:nth-child(2) .ant-select-item-option-content::before {
    background-color: #868FAE;
  }

  &:nth-child(3) .ant-select-item-option-content::before {
    background-color: #FF4646;
  }

  &:nth-child(4) .ant-select-item-option-content::before {
    background-color: #FA9703;
  }

  &:nth-child(5) .ant-select-item-option-content::before {
    background-color: #337FD8;
  }

  &:nth-child(6) .ant-select-item-option-content::before {
    background-color: #47D9B0;
  }
}



