@import "containers/Order/OrderCard/styles.less";
@import "containers/Order/ProductCard/styles.less";
@import "OrderModal/NewOrderModal/styles.less";
@import "containers/Order/AddToCart/styles.less";
@import "containers/Order/OrderFilter/styles.less";
@import "OrderModal/OrderCheckoutModal/styles.less";
@import "OrderModal/OrderDetailsModal/styles.less";

.OrderPageContentContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex-grow: 1;

  @media @mobile {
    padding: 0 16px;
  }
}

.OrderCardListContainer {
  display: flex;
  align-items: flex-start;
  gap: 18px 18px;
  align-self: stretch;
  flex-flow: row wrap;
  align-content: flex-start;
  .VerticalScrollInvisible();
}

.OrderCardListContainer.OrderCardListContainerEmpty {
  align-content: unset;
  flex-grow: 1;
}

.OrderCardListEmpty {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.OrderCardListEmptyImage {
  height: 274px;
  width: 319px
}

.OrderCardListEmptyHeader {
  margin-top: 64px;
  .Headline2();
}

.OrderCardListEmptyText {
  margin-top: 16px;
  width: 250px;
  text-align: center;
  .Subtitle2();
}

.OrderPageCreateNewOrderButton.ant-btn-default {
  border-color: @button-primary !important;
}