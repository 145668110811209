.HallPickerWrapper {
  width: 100vw;
  height: 100vh;
  background-color: @background-primary;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.HallPickerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  width: 728px;
  background-color: @background-secondary;
  border-radius: 24px;
  max-height: 55vh;
  margin: auto;
  padding: 32px 0;

  @media @mobile {
    max-width: calc(100vw - 32px);
    margin: auto 16px;
    padding: 24px 0;
    max-height: 60vh;
  }
}

.HallPickerHeader {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px;

  @media @mobile {
    flex-direction: column;
    align-items: unset;
    align-self: flex-start;
    gap: 24px;
    width: 100%;
  }
}

.HallPickerLogout {
  cursor: pointer;
  margin-right: 8px;
}

.HallPickerTitle {
  display: flex;
  .Headline2();
}

.HallPickerSearch .ant-input-affix-wrapper {
  width: 320px;

  @media @mobile {
    margin-left: 0;
    width: 100%;
  }
}

.HallPickerContent {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 32px;
  width: 100%;
  padding: 0 8px 0 32px;
  .VerticalScrollGutter();

  @media @mobile {
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
  }
}

.HallPickerItem {
  height: 56px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  background-color: @background-tertiary;
  color: @text-primary;

  &:hover {
    background-color: @button-primary;
    color: @text-analogous;
  }

  @media @mobile {
    height: 48px;
  }

  .HallPickerItemLabel {
    .Button1();
  }
}
