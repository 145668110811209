.ChangePhoneStatusModalHeader {
  text-align: center;
}

.ChangePhoneStatusModalFooter {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.ChangePhoneStatusModalPicture {
  display: flex;
  margin: 0 auto;

  &.ChangePhoneStatusModalPictureNew {
    transform: scale(-1, 1);
  }
}