.ChangePhoneFormModalFooter {
  display: flex;
  flex-direction: row;

  .DenyButton {
    margin-right: 24px;

    @media @mobile {
      margin-right: 16px;
    }
  }

  .ApproveButton {
  }
}

.ChangePhoneModalErrorContainer {
  margin-top: 32px;
}

.ChangePhoneModalErrorText {
  .Subtitle1();
  color: @red-420;
}