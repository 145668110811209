.HallListFilterManyHallsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
}

.HallListFilterSelectedHalls {
  display: flex;
  .Subtitle1();
}

.HallListFilterSelectedHallsCounterReset {
  cursor: pointer;
}

.HallListFilterSelectedHallsCounter {
  color: @icon-tertiary;
  margin-left: 4px;
}

.HallListFilterCheckboxAllWrapper {
  display: flex;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
}

.HallListFilterCheckboxWrapper, .HallPickerFilterCheckboxAllLabel, .HallListFilterCheckboxAllLabel {
  .Subtitle2();
}

.HallListFilterCheckboxWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 12px;
  max-height: 194px;
  .VerticalScroll();

  @media @mobile {
    min-height: 100px;
    height: fit-content;
  }
}

.HallListFilterCheckboxWrapper label, .HallPickerFilterCheckboxAllWrapper label {
  padding-bottom: 16px;
}

.HallListFilterCheckboxWrapper label:last-child {
  border-bottom: none !important;
}

.HallListMobileFlexWrapper {
  @media @mobile {
    display: flex;
    flex-direction: column;
  }
}