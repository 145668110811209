.NotificationContainer.ant-notification-notice {
  padding: 20px 24px;
  width: 554px;
  height: 138px;
  margin-bottom: 8px;
  border-radius: 16px;
  background-color: @separator-tertiary;
  .Shadow();


  .ant-notification-notice-with-icon {
    display: flex;
    flex-direction: row;
    gap: 24px;
  }

  .ant-notification-notice-icon {
    position: unset;


    .NotificationImageWrapper img {
      width: 110px;
      height: 90px;
    }

    @media @mobile {
      display: none;
    }
  }

  .ant-notification-notice-message {
    margin-bottom: unset !important;
    margin-inline-start: unset !important;
    padding-inline-end: unset !important;

    .NotificationTextContainer {
      display: flex;
      flex-direction: column;
      gap: 6px;

      .NotificationHeaderText {
        .Headline3();
      }

      .NotificationDetailsText {
        .Text2();
      }
    }
  }

  .ant-notification-notice-description {
    display: none;
  }

  .ant-notification-notice-close {
    color: unset;
    transition: unset;

    &:hover {
      color: unset;
      background-color: unset;
    }
  }

  @media @mobile {
    max-width: calc(100vw - 32px);
  }
}