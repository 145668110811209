@import "containers/MainMenu/HallPickerModal/styles.less";
@import "containers/MainMenu/LeftMenu/styles.less";


.MenuContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;

  height: 100vh;
  background-color: @background-primary;
  padding: 32px 0;
  transition: min-width 0.5s ease, max-width 0.5s ease;

  &.MinifiedMenuContainer {
    align-items: center;
  }

  @media @mobile {
    min-width: 100vw !important;
    max-width: 100vw !important;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
  }
}

.MenuContentContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.MenuContentContainer.MinifiedMenuContentContainer {
  align-items: center;
  width: 100%;
}

.LargeMenuContainer {
  min-width: 320px;
  max-width: 320px;

  .MenuContentContainer {
    gap: 24px;
  }
}

.MediumMenuContainer {
  min-width: 256px;
  max-width: 256px;

  .MenuContentContainer {
    gap: 16px;
  }
}

.MenuContainer.MinifiedMenuContainer.LargeMenuContainer {
  min-width: 72px;
  max-width: 72px;
}

.MenuContainer.MinifiedMenuContainer.MediumMenuContainer {
  min-width: 68px;
  max-width: 68px;
}

.MenuContainer {
  .MenuHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;

    .MenuHeaderLogoWrapper {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
}

.MenuHeaderIcon {
  width: 40px;
  height: 40px;
}

.MenuHeaderTextWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .TableHeader();
}

.MenuHeaderMobileContainer {
  @media @mobile {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.MenuSelectCurrentHall {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  padding: 8px 16px 8px 12px;
  border-radius: 24px;
  background-color: @background-secondary;
  cursor: pointer;

  & .CurrentHallText {
    display: inline-flex;
    align-items: center;
    .Subtitle2();
  }

  & .CurrentHallText::before {
    content: "";
    display: inline-flex;
    margin-right: 8px;
    width: 8px;
    height: 8px;
    border-radius: 8px;
    background-color: @icon-secondary;
  }

  .DropdownIcon {
    width: 10px;
    height: 10px;
    transition: rotate 0.3s;
  }

  @media @mobile {
    margin-right: 24px;
  }
}

.MenuMinifiedContainer {
  width: 100px;
  height: 100vh;
  background-color: @background-primary;
  padding: 28px 24px;
}

.MenuButtonWrapper {
  padding: 0 24px;
}

.MenuButtonWrapper .ant-btn-default {
  width: 100%;
}
