.Dashboard__Wrapper {
  display: flex;
  padding: 24px;
  background-color: @background-secondary;
  gap: 16px;
  border-radius: 24px;
  font-family: "Gilroy-700", sans-serif;

  &_Item {
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    gap: 2px;
    max-width: 342px;
    width: 100%;
    padding: 12px 24px;
  }

  &_Title {
    font-family: "Gilroy-700", sans-serif;
    font-size: 18px;
    line-height: 18px;
  }

  &_Count {
    font-family: "Gilroy-700", sans-serif;
    font-size: 28px;
    line-height: 28px;
  }
}

.AttendanceFilter {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .VerticalScrollInvisible();
  max-height: calc(100vh - 175px);

  &__TimeWrapper {
    display: flex;
    margin-top: 8px;
    .Caption();

    &_AdditionalTime {
      color: @text-secondary;
    }
  }
}

.AttendanceCharts {
  background-color: @background-secondary;
  padding: 32px 24px;
  border-radius: 24px;
  display: flex;
  flex-direction: column;

  &__Title {
    .Headline2()
  }

  &__Title_Wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
  }
}

.AttendanceTooltip {
  display: flex;
  flex-direction: column;
  font-family: 'Lato-400', sans-serif;

  &__Values {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-template-rows: repeat(2, auto);
  }

  &__YAxis {
    font-size: 14px;
    line-height: 20px;
  }

  &__Date {
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
  }
}

@media @mobile {
  .Dashboard__Wrapper {
    overflow-y: hidden;
    overflow-x: auto;
    min-height: 92px;
    padding: 16px 0 16px 16px;

    &_Item {
      border-radius: 12px;
      min-width: 143px;
      min-height: 60px;
    }

    &_Title {
      font-size: 14px;
      line-height: 14px;
    }

    &_Count {
      font-size: 18px;
      line-height: 18px;
    }
  }

  .AttendanceCharts {
    padding: 24px 16px;

    &__Title {
      .Headline3();
    }

    &__Title_Wrapper {
      flex-direction: column;
      gap: 16px;
      margin-bottom: 24px;
    }
  }
}
