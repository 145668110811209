.StatusLabel {
  padding: 4px 12px 4px 8px;
  background-color: @hover-tertiary;
  border-radius: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  .Overline1();

  &.Highlight {
    background-color: @hover-primary;
  }

  &::before {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 8px;
    border-radius: 4px;
  }

  &.yellow {
    &::before {
      background-color: @auxiliary-primary;
    }
  }

  &.green {
    &::before {
      background-color: @turquoise-420;
    }
  }

  &.red {
    &::before {
      background-color: @red-420;
    }
  }

  &.orange {
    &::before {
      background-color: @orange-420;
    }
  }

  &.grey {
    &::before {
      background-color: @grey-420;
    }
  }
}