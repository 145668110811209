.GamesReportStart {
  max-width: 864px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;

  &__Title {
    text-align: center;
    max-width: 520px;
    .Headline2();
  }

  &__Subtitle {
    color: @text-secondary;
    text-align: center;
  }
}

.GamesReportFilter {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .VerticalScrollInvisible();
  max-height: calc(100vh - 175px);

  .-TitleWrapper {
    display: flex;
    justify-content: space-between;
  }

  .-TimeWrapper {
    display: flex;
    margin-top: 8px;
    color: @text-primary;
    .Caption();


    .-additionalTime {
      color: @text-secondary;
    }
  }
}

.GamesReportTableIcon {
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &.-rotate {
    transform: rotate(180deg);
  }
}

.GamesReportTableInside {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__Row {
    display: flex;
    flex-direction: row;
    padding: 8px 0 8px 33px;
    .Text1();

    &_Total {
      background-color: @background-secondary;
      border-radius: 4px;
    }
  }

  &__Item {
    min-width: 135px;
    display: flex;
    align-items: center;

    &_Heading {
      display: flex;
      flex-direction: row;
      gap: 5px;
    }
  }
}

// 142 146 146 147 147 143

.GamesReportTableInside__Item:first-child {
  width: 15%;
  gap: 5px;
}

.GamesReportTableInside__Item:nth-child(2) {
  width: 15%
}

.GamesReportTableInside__Item:nth-child(3) {
  width: 15%;
  padding-left: 8px;
}

.GamesReportTableInside__Item:nth-child(4) {
  width: 15%;
  padding-left: 12px;
}

.GamesReportTableInside__Item:nth-child(5) {
  width: 15%;
  padding-left: 20px;
}

.GamesReportTableInside__Item:nth-child(6) {
  width: 15%;
  padding-left: 24px;
}

.GamesReportTableInside__Item:nth-child(7) {
  max-width: 124px;
  padding-left: 28px;
}

.GamesReportGamesList {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &__Wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

@media @mobile {
  .GamesReportStart {
    padding: 32px 16px 41px;

    &__Title {
      text-align: center;
      max-width: 340px;
      .Headline3();
    }

    &__Image {
      max-width: 280px;
    }
  }
}