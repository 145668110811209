.WorkersModal {
  .WorkersModalHeaderText {
    .Headline1();

    @media @mobile {
      .Headline3();
    }
  }

  &.-createModal {
    .-errorMessage {
      .Text1();
      font-size: 16px;
      line-height: 1.5;
      color: @red-420;
    }
  }

  &.-successModal {
    .WorkersModalHeaderText {
      display: flex;
      justify-content: center;
    }

    .-image {
      display: flex;
      margin: 32px auto;
    }

    .ant-modal-footer .ButtonWrapper {
      display: flex;
      justify-content: center;
    }
  }

  &.-confirmModal {
  }
}
