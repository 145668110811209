.Tabs {
  display: flex;
  margin: 0 auto;
  padding: 2px;
  border: 1px solid @separator-primary;
  border-radius: 12px;
  max-width: 100%;

  @media @mobile {
    margin: unset;
  }

  &Wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
  }

  &Item {
    min-width: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: @text-secondary;
    transition: color, background-color 0.3s ease-in-out;
    cursor: pointer;
    font-family: "Lato-600", sans-serif;
    font-size: 16px;
    line-height: 16px;
    padding: 10px;
    border-right: 1px solid @separator-primary;

    @media @mobile {
      min-width: unset;
      flex: 1 50%;
      padding: 10px;
      font-size: 14px;
      line-height: 16px;
    }

    &:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    &:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      border-right: none;
    }

    &Circle {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      flex-shrink: 0;
    }

    &Label {
      text-wrap: nowrap;
      text-overflow: ellipsis;
      max-width: 180px;
      overflow: hidden;
    }

    &Active {
      background-color: @hover-secondary;
      color: @text-primary;
    }

    &Disabled {
      opacity: 0.4;
      pointer-events: none;
    }
  }
}
